import React, { useMemo } from 'react';
import { graphql, PageProps } from 'gatsby';
import { useTranslation, Link, Trans } from 'gatsby-plugin-react-i18next';
import styled, { css } from 'styled-components';
import Layout from '~/components/layout';
import SEO from '~/components/seo';
import TitleWithCatchCopy from '~/components/TitleWithCatchCopy';
import RoundedButton from '~/components/RoundedButton';
import TextBreaks from '~/components/TextBreaks';
import {
  TopSection,
  Description,
  DescriptionWrapper,
  Section,
  TopTitleWrapper,
  PageTopThumbnailWrapper,
  StaticThumbnail,
  colors,
  media,
  commonKeyframes,
  easings,
} from '~/styles';
import { useIntersection } from '~/hooks';
import recruitThumbnail from '~/assets/images/recruit.jpg';
import { InterviewLinks } from '~/components/interview/organisms/InterviewLinks';

const RecruitPage: React.VFC<PageProps<GatsbyTypes.RecruitPageQuery>> = ({
  data: { rawCareers },
}) => {
  const { t, i18n } = useTranslation();
  const isJa = i18n.language === 'ja';
  const [isTextIntersection, textIntersectionRef] = useIntersection();
  const [isEducationIntersection, educationIntersectionRef] = useIntersection();
  const [isCareerIntersection, careerIntersectionRef] = useIntersection();
  const [isStaffInterviewIntersection, staffInterviewIntersectionRef] =
    useIntersection();

  const careers = useMemo(
    () =>
      rawCareers.nodes.map((career) => {
        const careerJa = {
          jobCategory: career.jobCategory,
          applicationConditions: career.applicationConditions,
          basicSalary: career.basicSalary,
          employmentStatus: career.employmentStatus,
          holidayVacation: career.holidayVacation,
          jobDescription: career.jobDescription,
          position: career.position,
          welfare: career.welfare,
          treatment: career.treatment,
        };

        const careerDataEn =
          career.i18n_refs && career.i18n_refs?.length > 0
            ? career.i18n_refs[0]?.ref
            : null;

        const careerEn = careerDataEn
          ? {
              jobCategory: careerDataEn.jobCategory,
              applicationConditions: careerDataEn.applicationConditions,
              basicSalary: careerDataEn.basicSalary,
              employmentStatus: careerDataEn.employmentStatus,
              holidayVacation: careerDataEn.holidayVacation,
              jobDescription: careerDataEn.jobDescription,
              position: careerDataEn.position,
              welfare: careerDataEn.welfare,
              treatment: careerDataEn.treatment,
            }
          : null;

        return {
          slug: career.slug?.current,
          localizedItem:
            i18n.language === 'en' && careerEn ? careerEn : careerJa,
        };
      }),
    [i18n.language, rawCareers],
  );

  return (
    <>
      <SEO title="Recruit" />
      <Layout>
        <TopSection>
          <PageTopThumbnailWrapper>
            <StaticThumbnail
              src={recruitThumbnail}
              style={{ objectPosition: '40% top' }}
              alt="thumbnail"
            />
          </PageTopThumbnailWrapper>
          <TopTitleWrapper>
            <TitleWithCatchCopy
              title="recruit.top.title"
              catchCopy="recruit.top.catchCopy"
              catchSub="recruit.top.catchSub"
              byKey
              top
              isIntersection
            />
          </TopTitleWrapper>
        </TopSection>
        <Section ref={textIntersectionRef}>
          <DescriptionWrapper
            showAnim
            isIntersection={isTextIntersection}
            delay={0.3}
          >
            <Description>{t('recruit.top.description')}</Description>
          </DescriptionWrapper>
        </Section>
        <Section ref={educationIntersectionRef}>
          <TitleWithCatchCopy
            title="recruit.education.title"
            catchCopy="recruit.education.catchCopy"
            catchSub="recruit.education.catchSub"
            byKey
            isIntersection={isEducationIntersection}
          />
          <DescriptionWrapper showAnim isIntersection={isEducationIntersection}>
            <Description>{t('recruit.education.description')}</Description>
            <RoundedButton text={t('common.seeMore')} to="/education" />
          </DescriptionWrapper>
        </Section>
        <Section id={'staffInterview'} ref={staffInterviewIntersectionRef}>
          <TitleWithCatchCopy
            catchCopy="Staff Interview"
            byKey
            isIntersection={isStaffInterviewIntersection}
          />
          <DescriptionWrapper
            showAnim
            isIntersection={isStaffInterviewIntersection}
          >
            <Description>
              {isJa
                ? 'SAFEHOUSEの制作現場をもっと知りたい方は、スタッフのインタビュー記事をご覧ください！'
                : 'If you want to know more about SAFEHOUSE production site, check out the staff interview articles!'}
            </Description>
            <InterviewLinks />
          </DescriptionWrapper>
        </Section>
        <Section ref={careerIntersectionRef}>
          <TitleWithCatchCopy
            title="recruit.careers.title"
            catchCopy="recruit.careers.catchCopy"
            byKey
            isIntersection={isCareerIntersection}
          />
          <DescriptionWrapper
            showAnim
            isIntersection={isCareerIntersection}
            delay={0.6}
          >
            <CareersDescription>
              <Trans
                i18nKey="recruit.careers.description"
                components={TextBreaks}
              />
            </CareersDescription>
          </DescriptionWrapper>
          <CategorizedCareers
            style={
              {
                '--title-delay': '1.05s',
                '--list-delay': '1.35s',
              } as React.CSSProperties
            }
          >
            <CareerCategoryTitle isIntersection={isCareerIntersection}>
              {t('careers.category.artist')}
            </CareerCategoryTitle>
            <CareerList isIntersection={isCareerIntersection}>
              {careers
                .filter(
                  (career) => career.localizedItem.jobCategory === 'artist',
                )
                .map((career) => (
                  <CareerButtonItem key={career.slug}>
                    <CareerButton to={`/careers#${career.slug}`}>
                      {career.localizedItem.position}
                    </CareerButton>
                  </CareerButtonItem>
                ))}
            </CareerList>
          </CategorizedCareers>
          <CategorizedCareers
            style={
              {
                '--title-delay': '1.8s',
                '--list-delay': '1.95s',
              } as React.CSSProperties
            }
          >
            <CareerCategoryTitle isIntersection={isCareerIntersection}>
              {t('careers.category.office')}
            </CareerCategoryTitle>
            <CareerList isIntersection={isCareerIntersection}>
              {careers
                .filter(
                  (career) => career.localizedItem.jobCategory === 'office',
                )
                .map((career) => (
                  <CareerButtonItem key={career.slug}>
                    <CareerButton to={`/careers#${career.slug}`}>
                      {career.localizedItem.position}
                    </CareerButton>
                  </CareerButtonItem>
                ))}
            </CareerList>
          </CategorizedCareers>
        </Section>
      </Layout>
    </>
  );
};

const CareersDescription = styled(Description)`
  ${media.mdUp} {
    text-align: center;
  }
`;

const CareerList = styled.ul<{ isIntersection: boolean }>`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  width: 100%;
  margin: 0;
  opacity: 0;
  transform: translateY(3em);

  ${media.mdDown} {
    grid-template-columns: 100%;
    max-width: 520px;
    grid-gap: 16px;
  }

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.45s ${easings.easeOutCubic}
          var(--list-delay) forwards,
        ${commonKeyframes.slideIn} 0.45s ${easings.easeOutCubic}
          var(--list-delay) forwards;
    `}
`;

const CareerButtonItem = styled.li`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CareerButton = styled(Link)`
  display: block;
  text-align: center;
  font-size: 24px;
  line-height: 1.5;
  border-radius: 999px;
  border: 2px solid ${colors.white};
  padding: 18px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  transition: transform 0.6s ${easings.easeOutCubic};

  &:hover {
    transition-duration: 0.2s;
    transform: scale(0.98);
  }

  ${media.mdDown} {
    font-size: 16px;
    padding: 12px;
  }
`;

const CareerCategoryTitle = styled.h4<{ isIntersection: boolean }>`
  text-align: center;
  font-weight: normal;
  font-size: 24px;
  white-space: pre-wrap;
  margin: 0;
  opacity: 0;
  color: ${colors.white};

  ${media.smDown} {
    font-size: 20px;
  }

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.45s ${easings.easeOutCubic}
          var(--title-delay) forwards,
        ${commonKeyframes.slideIn} 0.45s ${easings.easeOutCubic}
          var(--title-delay) forwards;
    `}
`;

const CategorizedCareers = styled.div`
  display: grid;
  gap: 40px;
`;

export default RecruitPage;

export const pageQuery = graphql`
  query RecruitPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    rawCareers: allSanityCareer(
      filter: { i18n_lang: { eq: "ja" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        jobCategory
        applicationConditions
        basicSalary
        employmentStatus
        holidayVacation
        id
        treatment
        welfare
        position
        jobDescription
        slug {
          current
        }
        i18n_refs {
          ref {
            ... on SanityCareer {
              id
              jobCategory
              applicationConditions
              basicSalary
              employmentStatus
              holidayVacation
              jobDescription
              position
              welfare
              treatment
            }
          }
        }
      }
    }
  }
`;
